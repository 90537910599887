import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import GameBoard from '../GameBoard/GameBoard.js'
import GameInfobar from '../GameInfobar/GameInfobar.js'
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay.js'
import AnswerFeed from '../AnswerFeed/AnswerFeed.js'
import ScoreBoard from '../ScoreBoard/ScoreBoard.js'
import DailyDouble from '../DailyDouble/DailyDouble.js'
import AnswerDisplay from '../AnswerDisplay.js'
import GameSummary from '../GameSummary/GameSummary.js'

import './GameRoom.css'
import '../QuestionDisplay/QuestionDisplay.css'

const GameState = {
   CHOOSING_QUESTION: 1,
   SHOWING_CATEGORY: 2,
   SHOWING_QUESTION: 3,
   SHOWING_ANSWER: 4,
   SHOWING_ANSWER_TIME_UP: 5,
   SHOWING_DAILY_DOUBLE: 6,
   SHOWING_DAILY_DOUBLE_QUESTION: 7,
   SHOWING_DAILY_DOUBLE_ANSWER: 8,
   SHOWING_DAILY_DOUBLE_ANSWER_TIME_UP: 9,
   GAME_OVER: 10,
};
Object.freeze(GameState);

const TIMER_START_VALUE = 15;
const NUM_QUESTIONS_ROUND_ONE = 30;
const NUM_QUESTIONS_TOTAL = 60;

const CORRECT_ANSWER_DING = new Audio("/correct_answer_ding.mp3")

function updateBoardState(boardState, category_index, question_index) {
    return boardState.map((category, category_idx) => {
        let newRow = category.map((question, question_idx) => {
            if (category_idx == category_index && question_idx == question_index && question == true) {
                return false;
            } else {
                return question;
            }
        });
        return newRow;
    })
}

class GameRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // for each category: 200, 400, 600, 800, 1000
            boardState: [
                [true, true, true, true, true],
                [true, true, true, true, true],
                [true, true, true, true, true],
                [true, true, true, true, true],
                [true, true, true, true, true],
                [true, true, true, true, true]
            ],

            /*
            possible game states:
                - the person is choosing a question
                    - everyone else should be disabled from choosing and UI should say that person is choosing
                - the person has chosen a question
                    - display the category that was chosen then after 3 seconds transition to showing the question
                - the time has expired on answering OR someone answered correctly
                    - show the correct answer and add the points
            */
            gameState: GameState.CHOOSING_QUESTION,
            currentQuestion: {},
            isChoosingQuestion:false,
            choosingPlayerId:'',
            choosingPlayerUsername:'',
            correctAnswer:'',
            scores:{},
            timeLeft:TIMER_START_VALUE,
            wager:0,
            doubleJeopardy: false
        };
        this.timer = 0;

        this.countdown = this.countdown.bind(this);
        this.makeWager = this.makeWager.bind(this);
        this.setupNewQuestion = this.setupNewQuestion.bind(this);
	}

    countdown() {
        let timeLeft = this.state.timeLeft - 1;
        this.setState({
            timeLeft: timeLeft
        });
        // Check if we're at zero.
        if (timeLeft == 0) {
            clearInterval(this.timer);
        }
    }

    makeWager(amount) {
        setTimeout(
            function() {
                this.props.socket.emit('madeWager', this.props.roomId, this.props.socket.id, amount);
            }
            .bind(this),
            1000
        );
    }

    setupNewQuestion(questionsAsked) {
        if (questionsAsked == NUM_QUESTIONS_ROUND_ONE) {
            this.setState({
                boardState:[
                    [true, true, true, true, true],
                    [true, true, true, true, true],
                    [true, true, true, true, true],
                    [true, true, true, true, true],
                    [true, true, true, true, true],
                    [true, true, true, true, true]
                ],
                gameState: GameState.CHOOSING_QUESTION,
                doubleJeopardy: true
            });
        } else if (questionsAsked == NUM_QUESTIONS_TOTAL) {
            this.setState({
                gameState: GameState.GAME_OVER,
            })
        } else {
            this.setState({
                gameState: GameState.CHOOSING_QUESTION
            });
        }
    }

    componentWillMount() {
        const socket = this.props.socket;

        // Initialize the scores dictionary from this.props.usersInRoom
        let s = {}
        this.props.usersInRoom.forEach(function (arrayItem) {
            s[arrayItem.user_id] = arrayItem.score
        });

        this.setState({scores: s})

        if (socket.id == this.props.startingPlayerId) {
            this.setState({isChoosingQuestion: true, choosingPlayerId: this.props.startingPlayerId, choosingPlayerUsername:this.props.startingPlayerUsername})
        } else {
            this.setState({isChoosingQuestion: false, choosingPlayerId: this.props.startingPlayerId, choosingPlayerUsername:this.props.startingPlayerUsername})
        }

        socket.on("questionClicked",  (category_index, question_index, isDailyDouble) => {
            let c_index = this.state.doubleJeopardy ? 6+category_index : category_index
            let questionDict = this.props.questionAnswers[c_index][question_index];

            if (isDailyDouble) {
                this.setState({
                    currentQuestion: {
                        "question": questionDict["question"],
                        "answer": questionDict["answer"],
                        "category": this.props.categories[c_index],
                        "value": questionDict["value"],
                        "question_id": category_index.toString()+question_index.toString()
                    },
                    gameState: GameState.SHOWING_DAILY_DOUBLE,
                    boardState: updateBoardState(this.state.boardState, category_index, question_index)
                })

            } else {
                this.setState({
                    currentQuestion: {
                        "question": questionDict["question"],
                        "answer": questionDict["answer"],
                        "category": this.props.categories[c_index],
                        "value": questionDict["value"],
                        "question_id": category_index.toString()+question_index.toString()
                    },
                    gameState: GameState.SHOWING_CATEGORY,
                    boardState: updateBoardState(this.state.boardState, category_index, question_index)
                })

                setTimeout(
                    function() {
                        this.setState({gameState: GameState.SHOWING_QUESTION});
                        this.timer = setInterval(this.countdown, 1000);
                    }
                    .bind(this),
                    3000
                );
            }

        })

        socket.on("wagerMade", (amount) => {
            this.setState({gameState: GameState.SHOWING_DAILY_DOUBLE_QUESTION, wager: amount});
            this.timer = setInterval(this.countdown, 1000);
        })

        socket.on("timeDone", (questionsAsked) => {
            // QUESTIONS ASKED

            let correctAnswer = this.state.currentQuestion
            clearInterval(this.timer)
            this.setState({
                currentQuestion: {},
                gameState: GameState.SHOWING_ANSWER_TIME_UP,
                timeLeft: TIMER_START_VALUE,
                correctAnswer: correctAnswer,
            })

            setTimeout(
                function() {
                    this.setupNewQuestion(questionsAsked)
                }
                .bind(this),
                5000
            );
        })

        socket.on("dailyDoubleTimeDone", (user_id, score, questionsAsked) => {
            // QUESTIONS ASKED
            let correctAnswer = this.state.currentQuestion
            clearInterval(this.timer)
            this.setState({
                currentQuestion: {},
                gameState: GameState.SHOWING_DAILY_DOUBLE_ANSWER_TIME_UP,
                correctAnswer: correctAnswer,
                scores: {...this.state.scores, [user_id]: score},
                timeLeft:TIMER_START_VALUE
            })
            setTimeout(
                function() {
                    this.setupNewQuestion(questionsAsked)
                }
                .bind(this),
                5000
            );
        })

        socket.on("answeredCorrect", (user_id, username, score, questionsAsked) => {
            // QUESTIONS ASKED

            let correctAnswer = this.state.currentQuestion

            if (user_id == socket.id) {
                CORRECT_ANSWER_DING.play()
            }
            clearInterval(this.timer)
            this.setState({
                currentQuestion: {},
                gameState: GameState.SHOWING_ANSWER,
                isChoosingQuestion: (user_id == socket.id),
                choosingPlayerUsername: username,
                choosingPlayerId: user_id,
                correctAnswer: correctAnswer,
                scores: {...this.state.scores, [user_id]: score},
                timeLeft:TIMER_START_VALUE
            })
            setTimeout(
                function() {
                    this.setupNewQuestion(questionsAsked)
                }
                .bind(this),
                5000
            );
        })

        socket.on("answeredDailyDouble", (user_id, username, score, questionsAsked) => {
            let correctAnswer = this.state.currentQuestion

            clearInterval(this.timer)
            this.setState({
                currentQuestion: {},
                gameState: GameState.SHOWING_DAILY_DOUBLE_ANSWER,
                correctAnswer: correctAnswer,
                scores: {...this.state.scores, [user_id]: score},
                timeLeft:TIMER_START_VALUE
            })
            setTimeout(
                function() {
                    this.setupNewQuestion(questionsAsked)
                }
                .bind(this),
                5000
            );
        })
    }

    render() {
        let board;
        if (this.state.gameState == GameState.SHOWING_QUESTION ||
            this.state.gameState == GameState.SHOWING_CATEGORY ||
            this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_QUESTION) {
            board = <QuestionDisplay
                        isShowingCategory={this.state.gameState == GameState.SHOWING_CATEGORY}
                        question={this.state.currentQuestion} />
        } else if (this.state.gameState == GameState.SHOWING_DAILY_DOUBLE) {
            // the person answering the  daily double is the person that just chose the question.
            board = <DailyDouble
                        isAnswering={this.state.isChoosingQuestion}
                        score={this.state.scores[this.props.socket.id]}
                        makeWager={this.makeWager}
                        isDoubleJeopardy={this.state.doubleJeopardy}
                        category={this.state.currentQuestion["category"]}/>

        } else if (this.state.gameState == GameState.CHOOSING_QUESTION) {
            board = <GameBoard
                        isChoosing={this.state.isChoosingQuestion}
                        socket={this.props.socket}
                        categories={this.props.categories}
                        roomId={this.props.roomId}
                        questionAnswers={this.props.questionAnswers}
                        boardState={this.state.boardState}
                        isDoubleJeopardy={this.state.doubleJeopardy} />
        } else {
            board = <AnswerDisplay
                        didTimeUp={this.state.gameState == GameState.SHOWING_ANSWER_TIME_UP || this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_ANSWER_TIME_UP}
                        isDailyDouble={this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_ANSWER || this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_ANSWER_TIME_UP}
                        correctAnswererUsername={this.state.choosingPlayerUsername}
                        correctAnswererId={this.state.choosingPlayerId}
                        correctAnswer={this.state.correctAnswer["answer"][0]}
                        value={this.state.correctAnswer["value"]}
                        wager={this.state.wager}
                        isChoosingQuestion={this.state.isChoosingQuestion} />
        }

        let component;
        if (this.state.gameState == GameState.GAME_OVER) {
            component = <div className="game-room-container">
                    <div className="corner-logo">
                        <a href="/">
                            <img src="/jeo-party_small.png" />
                        </a>
                    </div>
                    <GameSummary
                        scores={this.state.scores}
                        usersInRoom={this.props.usersInRoom} />
                </div>
        } else {
            component = <div className="game-room-container">
                <div className="corner-logo">
                    <a href="/">
                        <img src="/jeo-party_small.png" />
                    </a>
                </div>
                <GameInfobar
                    gameState={this.state.gameState}
                    isChoosing={this.state.isChoosingQuestion}
                    choosingPlayerUsername={this.state.choosingPlayerUsername}
                    category={this.state.currentQuestion["category"]}
                    value={this.state.currentQuestion["value"]}
                    timeLeft={this.state.timeLeft}
                    wager={this.state.wager}
                    isDoubleJeopardy={this.state.doubleJeopardy}/>
                <div className="game-room-content">
                    <ScoreBoard
                        usersInRoom={this.props.usersInRoom}
                        scores={this.state.scores}/>
                    {board}
                    <AnswerFeed
                        isEnabled={this.state.gameState == GameState.SHOWING_QUESTION || this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_QUESTION}
                        isDailyDouble={this.state.gameState == GameState.SHOWING_DAILY_DOUBLE_QUESTION}
                        isDailyDoubleAnswerer={this.state.isChoosingQuestion}
                        wager={this.state.wager}
                        socket={this.props.socket}
                        room_id={this.props.roomId}
                        question={this.state.currentQuestion}
                        username={this.props.username}/>
                </div>
            </div>
        }

        return (
            component
        )
    }
}

export default withRouter(GameRoom);
