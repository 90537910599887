import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import { checkAnswer } from '../../checkAnswer.js'

import './AnswerFeed.css'

var stringSimilarity = require('string-similarity');

function checkAnswerNew(guess, actualAnswer) {
    if (stringSimilarity.compareTwoStrings(guess, actualAnswer) > 0.65) {
        return true
    }
    return false
}

class AnswerFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerText: '',
            attemptedAnswers: []
        };

        this.submitAnswer = this.submitAnswer.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.checkAnswer = this.checkAnswer.bind(this)
        this.scrollToBottom = this.scrollToBottom.bind(this)

        this.answerFeedRef = React.createRef();
	}

    componentWillMount(){
        const socket = this.props.socket

        socket.on("answerAttempt", (username, answer_attempt) => {
            let payload = {
                username: username,
                answer_attempt: answer_attempt
            }
            this.setState({
                attemptedAnswers: [...this.state.attemptedAnswers, payload]
            })
            this.scrollToBottom()
        })
    }

    scrollToBottom() {
            const scrollHeight = this.answerFeedRef.scrollHeight;
          const height = this.answerFeedRef.clientHeight;
          const maxScrollTop = scrollHeight - height;
          this.answerFeedRef.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    checkAnswer(guess) {
        let answers = this.props.question["answer"]
        guess = guess.toLowerCase();
        guess = guess.replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '');
        guess = guess.replace(/\s{2,}/g," ")

        if (answers.length == 1) {
            var answer = answers[0].toLowerCase()
            answer = answer.replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '');
            answer = answer.replace(/\s{2,}/g," ")
            if(answer.length <= 4) {
                if (answer == guess) {
                    if (this.props.isDailyDouble) {
                        this.props.socket.emit('correctDailyDouble', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.wager)
                    } else {
                        this.props.socket.emit('correctAnswer', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.question["value"])
                    }
                } else {
                    this.props.socket.emit('wrongAnswer', this.props.room_id, this.props.username, this.props.socket.id, guess)
                }
            } else if (checkAnswerNew(guess, answer)) {
                if (this.props.isDailyDouble) {
                    this.props.socket.emit('correctDailyDouble', this.props.room_id,  this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.wager)
                } else {
                    this.props.socket.emit('correctAnswer', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.question["value"])
                }
            } else {
                this.props.socket.emit('wrongAnswer', this.props.room_id, this.props.username, this.props.socket.id, guess)
            }
        }
        else if (answers.length == 2) {
            for(var i=0; i<answers.length; i++) {
                var answer = answers[i].toLowerCase()
                answer = answer.replace(/[\.,-\/#!$%\^&\*;:{}=\-_`~()@\+\?><\[\]\+]/g, '');
                answer = answer.replace(/\s{2,}/g," ")
                if(answer.length <= 4) {
                    if (answer == guess) {
                        if (this.props.isDailyDouble) {
                            this.props.socket.emit('correctDailyDouble', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.wager)
                        } else {
                            this.props.socket.emit('correctAnswer', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.question["value"])
                        }
                        break;
                    } else {
                        if (i==1) {
                            this.props.socket.emit('wrongAnswer', this.props.room_id, this.props.username, this.props.socket.id, guess)
                        }
                    }
                } else if (checkAnswerNew(guess, answer)) {
                    if (this.props.isDailyDouble) {
                        this.props.socket.emit('correctDailyDouble', this.props.room_id,  this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.wager)
                    } else {
                        this.props.socket.emit('correctAnswer', this.props.room_id, this.props.username, this.props.socket.id, this.props.question["question_id"], this.props.question["value"])
                    }
                    break;
                } else {
                    if (i==1) {
                        this.props.socket.emit('wrongAnswer', this.props.room_id, this.props.username, this.props.socket.id, guess)
                    }
                }
            }

        }
    }

    submitAnswer(event) {
        event.preventDefault();
        if (this.state.answerText == '') {
            event.target.reset();
            return;
        }
        // check the answer for validity if it's a daily double answer and the person is the daily double answerer or if its a normal question`
        if ((this.props.isDailyDouble && this.props.isDailyDoubleAnswerer) || (!this.props.isDailyDouble && this.props.isEnabled)) {
            this.checkAnswer(this.state.answerText)
        } else {
            this.props.socket.emit('wrongAnswer', this.props.room_id, this.props.username, this.props.socket.id, this.state.answerText)
        }
        event.target.reset();
        // this.setState({answerText: ''});
    }

    handleChange(event) {
        this.setState({answerText: event.target.value});
    }

    render() {
        return (
            <div className={"answer-box"}>
                <div className="answer-feed" ref={(div) => {this.answerFeedRef = div;}}>
                    {this.state.attemptedAnswers.map((data, idx) => {
                        return (
                            <div className={idx % 2 == 0 ? "answer-attempt-container-a" : "answer-attempt-container-b"}>
                                <div className="answer-attempt-username">
                                    {data.username}:
                                </div>
                                <div className="answer-attempt-answer">
                                    {data.answer_attempt}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <form onSubmit={this.submitAnswer} className="answer-input-form">
                    <input className="answer-input" placeholder="Type your answer here..." onChange={this.handleChange}/>
                </form>
            </div>
        )
    }
}

export default withRouter(AnswerFeed);
