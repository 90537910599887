import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import './QuestionDisplay/QuestionDisplay.css'

function AnswerDisplay(props) {
    let answererText;
    if (props.didTimeUp) {
        if (props.isDailyDouble) {
            answererText = <div className="answerer-and-value-container">
                                <div className="answerer-text"> {props.isChoosingQuestion ? "You ran out of time!" : props.correctAnswererUsername + " ran out of time!"}</div>
                                    {props.isChoosingQuestion && <div className="answer-value-text-red">-$ {props.wager}</div>}

                           </div>
        } else {
            answererText = <div className="answerer-and-value-container">
                                <div className="answerer-text"> You ran out of time! </div>
                           </div>
        }
    } else {
        if (props.isDailyDouble) {
            answererText = <div className="answerer-and-value-container">
                                <div className="answerer-text">{props.isChoosingQuestion ? "You got it!" : props.correctAnswererUsername + " got it!"}</div>
                                {props.isChoosingQuestion && <div className="answer-value-text-green">+$ {props.wager}</div>}
                            </div>
        } else {
            answererText = <div className="answerer-and-value-container">
                <div className="answerer-text">{props.isChoosingQuestion ? "You " : props.correctAnswererUsername} answered first!</div>
                {props.isChoosingQuestion && <div className="answer-value-text">+{props.value}</div>}
            </div>
        }

    }

    return <div className="answer-container">
                {answererText}
                <div className="answer-text-container">
                    <div>the correct answer was: </div>
                    <div className="answer-text"> {props.correctAnswer} </div>
                </div>
            </div>
}

export default withRouter(AnswerDisplay);
