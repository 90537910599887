import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import { isMobile } from "react-device-detect";

import './Landing.css'
import GameRoom from '../GameRoom/GameRoom.js'
import WaitingRoom from '../WaitingRoom/WaitingRoom.js'

class InitialPage extends Component{
    constructor(props){
        super(props);
        this.sigCanvas = '';

        this.createRoom = this.createRoom.bind(this);
        this.joinRoom = this.joinRoom.bind(this);
    }

    // TODO: ADD A CHECK TO SEE IF CANVAS IS EMPTY
    createRoom = () => {
        if(this.sigCanvas.isEmpty()) {
            alert("Make sure to draw your Jeopardy signature in the blue box!");
        } else if (this.props.username.length == 0) {
            alert("Make sure to enter your name!");
        }
        else {
            this.props.createRoom(this.sigCanvas.toDataURL('image/jpeg', 0.25));
        }
    }

    joinRoom = () => {
        if(this.sigCanvas.isEmpty()) {
            alert("Make sure to draw your Jeopardy signature in the blue box!");
        } else if (this.props.username.length == 0) {
            alert("Make sure to enter your name!");
        } else {
            this.props.joinRoom(this.sigCanvas.toDataURL('image/jpeg', 0.25));
        }
    }

    render() {
        return (
            <div className="join-create-container">
                <input className="name-input" type="text" value={this.props.username} onChange={this.props.handleChange} placeholder="Enter your name" />
                <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }} penColor='white' backgroundColor="#020685" canvasProps={{className: 'sigCanvas'}}  />
                <hr style={{width:"100%", color: "grey", backgroundColor: "grey", height:"1%", borderStyle:"solid", alignSelf:"center"}}/>
                <div className="join-create-button-container">
                    <button className="create-button" onClick={this.createRoom}> CREATE GAME </button>
                    <button className="join-button" onClick={this.joinRoom}> JOIN YOUR FRIEND'S GAME </button>
                </div>
            </div>
        );
    }

}

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	socket:null,
            username:'',
            didCreateRoom:false,
            isWaiting:false,
            roomId:'',
            usersInRoom:[],
            inGame:false,
            inviteLinkText:'Hover over me to see the invite link!',
            questions:{},
            startingPlayerId:'',
            startingPlayerUsername:'',
        };
        this.handleChange = this.handleChange.bind(this);
        this.createRoom = this.createRoom.bind(this);
        this.joinRoom = this.joinRoom.bind(this);
        this.startGame = this.startGame.bind(this);
	}

    componentWillMount(){
        const values = queryString.parse(this.props.location.search)
        this.setState({roomId: values.room})

        const socket = this.props.socket;

        socket.on("newPlayer",  (users) => {
            this.setState({usersInRoom: users})
        })

        socket.on("gameStarted",  (questions, startingPlayerId, startingPlayerUsername, users) => {
            this.setState({inGame: true,
                            questions: questions,
                            startingPlayerId: startingPlayerId,
                            startingPlayerUsername: startingPlayerUsername,
                            usersInRoom:users})
        })

        socket.on("removePlayer", (socket_id, users) => {
            this.setState({usersInRoom:users})
        })
    }

    handleChange(event) {
        this.setState({username: event.target.value});
    }

    startGame = (difficulty) => {
        const socket = this.props.socket
        if (difficulty == null) {
            alert("Pick a difficulty level")
        }
        socket.emit("startGame", this.state.roomId, difficulty, (roomId) => {
            // TODO: ERROR CHECKING!!!
        });
    }

    createRoom = (canvasImage) => {
        const socket = this.props.socket
        socket.emit("createRoom", this.state.username, canvasImage, (roomId) => {
            // TODO: ERROR CHECKING!!
            this.setState({didCreateRoom: true, isWaiting: true, roomId: roomId})
        });
    }

    joinRoom = (canvasImage) => {
        const socket = this.props.socket
        socket.emit("joinRoom", this.state.username, canvasImage, this.state.roomId, (roomId, errCode) => {
            if (errCode == "success") {
                this.setState({isWaiting: true, roomId: roomId})
            } else if (errCode == "notFound") {
                alert("We couldn't find that room, please make sure you copied your friend's URL correctly.")
            } else if (errCode == "gameStarted") {
                alert("The game has already started. You cannot join an ongoing game")
            }
        });
    }

    // TODO: when isWaiting is true, hide the buttons and show the people in the room
    render() {
        let landingContent;
        if (this.state.isWaiting) {
            landingContent = <WaitingRoom
                            usersInRoom={this.state.usersInRoom}
                            roomId={this.state.roomId}
                            startGame={this.startGame}
                            frontendRootURL={this.props.frontendRootURL}
                            didCreateRoom={this.state.didCreateRoom}/>
        } else {
            landingContent = <InitialPage
                            username={this.state.username}
                            handleChange={this.handleChange}
                            createRoom={this.createRoom}
                            joinRoom={this.joinRoom}
                            />
        }

        let component;
        if (this.state.inGame) {
            let categories = []
            let questionAnswers = []
            let questions_array = this.state.questions
            for(var i=0; i < questions_array.length; i++) {
                categories.push(questions_array[i]["category"])
                questionAnswers.push(questions_array[i]["questions"])
            }

            component = <GameRoom
                            socket={this.props.socket}
                            username={this.state.username}
                            roomId={this.state.roomId}
                            usersInRoom={this.state.usersInRoom}
                            categories={categories}
                            questionAnswers={questionAnswers}
                            startingPlayerId={this.state.startingPlayerId}
                            startingPlayerUsername={this.state.startingPlayerUsername}/>
        } else {
            const copyright = 'This game is not affiliated with, sponsored by, or operated by Jeopardy Productions, Inc in any way. \n Jeo-Party! is not and never will be for profit; there are NO advertisements, and there is NO price to pay. If this game is distributed elsewhere with either of those costs, it is without my knowledge, and without my consent';
            component = <div className="landing-container">
                            <div className="header-logo">
                                <a href="/">
                                    <img src="/jeo-party.png" />
                                </a>
                                <h2> PLAY JEOPARDY AGAINST YOUR FRIENDS IN REAL-TIME! </h2>
                            </div>
                            <div className="landing-content">
                                {landingContent}
                                <div className="copyright-notice">
                                    {copyright}
                                </div>
                            </div>
                        </div>;
        }

        if (isMobile) {
            component = <div className="mobile-background">  <h1> This game is designed for laptop/desktop screens only. </h1> </div>
        }
        return (
            component
        );
    }
}

export default withRouter(Landing);
