import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import './DailyDouble.css'

class DailyDouble extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
	}

    componentWillMount(){
        let audio = new Audio("/dailydouble.mp3")
        audio.volume = 0.2;
        audio.play()
        if (this.props.isAnswering) {
            setTimeout(
                function() {
                    // reprompt if null or if invalid value.
                    var value = prompt("Enter your wager (risk up to $" + Math.max(this.props.score, (this.props.isDoubleJeopardy ? 2000 : 1000)) + ")" + "\n" + "The category is: " + this.props.category)
                    let intVal = parseInt(value)
                    while(intVal < 0 || intVal > Math.max(this.props.score, (this.props.isDoubleJeopardy ? 2000 : 1000)) || value == null || isNaN(intVal)) {
                        value = prompt("Enter a valid wager (risk between 0 and " +  Math.max(this.props.score, (this.props.isDoubleJeopardy ? 2000 : 1000)) + ")" + "\n" + "The category is: " + this.props.category)
                        intVal = parseInt(value)
                    }
                    this.props.makeWager(parseInt(value));
                }
                .bind(this),
                3000
            );
        }
    }


    render() {
        return (
            <div class="dd-image-container">
            </div>
        )
    }
}

export default withRouter(DailyDouble);
