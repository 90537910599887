import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import io from 'socket.io-client'
import './App.css';
import Landing from './Landing/Landing.js';

// const socketUrl = "http://localhost:8000"
//
// var a = Math.floor(Math.random() * 3);
//
// var backendURL = "localhost:8000"
//
// if (a == 1) {
//     backendURL = "localhost:8001"
// }
// if (a == 2) {
//     backendURL = "localhost:8002"
// }
//
// // console.log(backendURL)
// const frontendRootURL = "localhost:"+process.env.PORT

const backendURL = "multi-jeopardy-backend-env.eba-nhfu6gxe.us-west-2.elasticbeanstalk.com"
const frontendRootURL = "jeo-party.com/"


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	socket:null
        };
	}

    componentWillMount(){
        this.initSocket()
    }

    initSocket = () => {
		const socket = io(backendURL)

		socket.on('connect', ()=>{
		})

		this.setState({socket})
    }

    render() {
        const { socket } = this.state
        return (
            <Router>
                <Route path='/' component={() => <Landing backendURL={backendURL} frontendRootURL={frontendRootURL} socket={socket} />}/>
            </Router>
        );
    }
}

export default App;
