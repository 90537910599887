import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import './GameInfobar.css'

const GameState = {
   CHOOSING_QUESTION: 1,
   SHOWING_CATEGORY: 2,
   SHOWING_QUESTION: 3,
   SHOWING_ANSWER: 4,
   SHOWING_ANSWER_TIME_UP: 5,
   SHOWING_DAILY_DOUBLE: 6,
   SHOWING_DAILY_DOUBLE_QUESTION: 7,
};

Object.freeze(GameState);

function GameInfobar(props) {
    let textToShow;
    if (props.gameState == GameState.CHOOSING_QUESTION) {
        if(props.isChoosing) {
            textToShow = <div className="picking-category"> Pick a category</div>
        } else {
            textToShow = <div className="picking-category"> {props.choosingPlayerUsername} is picking a category</div>
        }
    } else if (props.gameState == GameState.SHOWING_QUESTION) {
        textToShow = <div className="showing-question"> {props.category}, {props.value}</div>
    } else if (props.gameState == GameState.SHOWING_DAILY_DOUBLE) {
        textToShow = <div className="showing-question"> {props.isChoosing ? "You got the daily double! Make a wager" : props.choosingPlayerUsername+ " is making a wager"}</div>
    } else if (props.gameState == GameState.SHOWING_DAILY_DOUBLE_QUESTION) {
        if(props.isChoosing) {
            textToShow = <div className="picking-category"> {props.category}, ${props.wager}</div>
        } else {
            textToShow = <div className="showing-question"> {props.choosingPlayerUsername} wagered ${props.wager} </div>
        }
    }

    return(
        /*
            should show:
                - when player is choosing: "Pick a category"
                - when player is not choosing: "__ is picking a category"

                - when question is displayed: "Category for $200"

            in the future: show a timer for how long is left to answer.

        */
        <div className="game-info-bar">
            <div className="round">
                {props.isDoubleJeopardy ? "Round 2/2" : "Round 1/2"}
            </div>
            {textToShow}
            <div className={((props.gameState == GameState.SHOWING_QUESTION ||
                                props.gameState == GameState.SHOWING_DAILY_DOUBLE_QUESTION)) ? "timer" : "timer-hidden"}>
                {props.timeLeft}
            </div>
        </div>
    );
}

export default withRouter(GameInfobar);
