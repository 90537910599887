import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import './GameBoard.css'

class GameBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.priceValueFromQuestionIndex = this.priceValueFromQuestionIndex.bind(this);
        this.clickedQuestion = this.clickedQuestion.bind(this);

        this.jeopardyCategories = this.props.categories.slice(0, 6);
        this.jeopardyQuestions = this.props.questionAnswers.slice(0, 6);
        this.doubleJeopardyCategories = this.props.categories.slice(6, 12);
        this.doubleJeopardyQuestions = this.props.questionAnswers.slice(6, 12);
	}

    componentWillMount(){
        // TODO Jeopardy and then Double JEOPARDY
        // for now just show the jeopardy questions
    }

    priceValueFromQuestionIndex(questionIndex) {
        switch(questionIndex) {
            case 0:
                if(this.props.isDoubleJeopardy) {
                    return "$ 400"
                }
                return "$ 200"
            case 1:
                if(this.props.isDoubleJeopardy) {
                    return "$ 800"
                }
                return "$ 400"
            case 2:
                if(this.props.isDoubleJeopardy) {
                    return "$ 1200"
                }
                return "$ 600"
            case 3:
                if(this.props.isDoubleJeopardy) {
                    return "$ 1600"
                }
                return "$ 800"
            case 4:
                if(this.props.isDoubleJeopardy) {
                    return "$ 2000"
                }
                return "$ 1000"
        }
    }

    clickedQuestion(event) {
        const id = event.target.id;
        const socket = this.props.socket;
        const roomId = this.props.roomId;

        let category_index = parseInt(id[0])
        let question_index = parseInt(id[1])

        socket.emit("clickedQuestion", roomId, category_index, question_index);
    }


    render() {
        var categories = this.jeopardyCategories;
        var questionAnswers = this.jeopardyQuestions;
        if (this.props.isDoubleJeopardy) {
            categories = this.doubleJeopardyCategories;
            questionAnswers = this.doubleJeopardyQuestions
        }
        return (
            <div class="divTable">
                <div class="divTableCategoryRow">
                    {categories.map((category, idx) =>{
                       return (<div class="divTableHeaderCell">{category}</div>)
                     })}
                </div>
                <div class={this.props.isChoosing ? 'divTableQuestionsContainer' : 'divTableQuestionsContainer-off'}>
                    {questionAnswers.map((questions, category_index) => {
                        return(<div class="divTableQuestionsColumn">
                                {
                                    questions.map((question, question_index) => {
                                        let is_cell_enabled = this.props.boardState[category_index][question_index]
                                        let unique_id = category_index.toString()+question_index.toString()
                                        if (is_cell_enabled) {
                                            return <div id={unique_id} class="divTableQuestionCell" onClick={this.clickedQuestion}> {this.priceValueFromQuestionIndex(question_index)} </div>
                                        } else {
                                            return <div id={unique_id} class="divTableQuestionCell-off"></div>
                                        }

                                    })
                                }
                                </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default withRouter(GameBoard);
