import { removeDiacritics } from './diacritics.js';
import { stemmer } from './porter.js';
function levenshtein( a, b )
{
	var i;
	var j;
	var cost;
	var d = new Array();

	if ( a.length == 0 )
	{
		return b.length;
	}

	if ( b.length == 0 )
	{
		return a.length;
	}

	for ( i = 0; i <= a.length; i++ )
	{
		d[ i ] = new Array();
		d[ i ][ 0 ] = i;
	}

	for ( j = 0; j <= b.length; j++ )
	{
		d[ 0 ][ j ] = j;
	}

	for ( i = 1; i <= a.length; i++ )
	{
		for ( j = 1; j <= b.length; j++ )
		{
			if ( a.charAt( i - 1 ) == b.charAt( j - 1 ) )
			{
				cost = 0;
			}
			else
			{
				cost = 1;
			}

			d[ i ][ j ] = Math.min( d[ i - 1 ][ j ] + 1, d[ i ][ j - 1 ] + 1, d[ i - 1 ][ j - 1 ] + cost );

			if(
         i > 1 &&
         j > 1 &&
         a.charAt(i - 1) == b.charAt(j-2) &&
         a.charAt(i-2) == b.charAt(j-1)
         ){
          d[i][j] = Math.min(
            d[i][j],
            d[i - 2][j - 2] + cost
          )

			}
		}
	}

	return d[ a.length ][ b.length ];
}

const stem = word => stemmer(word.replace(/ez$/g, 'es').replace(/[^\w]/g, ''));

const stopwords = ""
let stopnames = ""


const reduceLetter = function(letter) {
		if (['z', 's', 'k', 'c'].includes(letter)) { return 's'; }
		if (['e', 'a', 'o', 'u', 'y', 'i'].includes(letter)) { return 'e'; }
		return letter;
	};

const reduceAlphabet = function(word) {
	const letters = (Array.from(word.split('')).map((letter) => reduceLetter(letter)));
	return letters.join('');
};

const levens = (a, b) => levenshtein(reduceAlphabet(a), reduceAlphabet(b));


const checkWord = function(word, list) {
		let len, real;
		let valid, score;
		let scores = (() => {
			const result = [];
			for (valid of Array.from(list)) {
				score = levens(valid, word);
				result.push([score, valid.length - score, valid.length, valid]);
			}
			return result;
		})();
		if (scores.length === 0) {
			return '';
		}
		scores = scores.sort((a, b) => a[0] - b[0]);
		[score, real, len, valid] = Array.from(scores[0]);
		const frac = real / len;
		if (len > 4) {
			if (frac >= 0.65) {
				return valid;
			}
		} else {
			if (frac >= 0.60) {
				return valid;
			}
		}
		return '';
	};

const isPerson = function(answer) {
		// filter out words less than 3 letters long because they suck
		let name;
		const canon = ((() => {
			const result = [];
			for (name of Array.from(answer.split(/\s+/))) {
				if (name.length > 3) {
					result.push(name);
				}
			}
			return result;
		})());
		// find words of the canon (seriously i dont know what to call it)
		const caps = ((() => {
			const result1 = [];
			for (name of Array.from(canon)) {
				if ("A" <= name[0] && name[0] <= "Z") {
					result1.push(name);
				}
			}
			return result1;
		})());
		// if all words that matter are caps, that means its a person woot
		return caps.length === canon.length;
	};

const parseAnswer = function(answer) {
		let part;
		answer = answer.replace(/[\[\]\<\>\{\}][\w\-]+?[\[\]\<\>\{\}]/g, '');

		let clean = ((() => {
			const result = [];
			for (part of Array.from(answer.split(/[^\w]or[^\w]|\[|\]|\{|\}|\;|\,|\<|\>|\(|\)/g))) {
				result.push(part.trim());
			}
			return result;
		})());
		clean = ((() => {
			const result1 = [];
			for (part of Array.from(clean)) {
				if (part !== '') {
					result1.push(part);
				}
			}
			return result1;
		})());
		const pos = [];
		const neg = [];
		for (part of Array.from(clean)) {
			part = removeDiacritics(part); //clean out some non-latin characters
			part = part.replace(/\"|\'|\“|\”|\.|’|\:/g, '');
			part = part.replace(/-/g, ' ');

			if (/equivalent|word form|other wrong/.test(part)) {
				// log 'equiv-', part
			} else if (/do not|dont/.test(part)) {
				// log 'neg-', part
				neg.push(part);
			} else if (/accept/.test(part)) {
				const comp = part.split(/before|until/);
				if (comp.length > 1) {
					neg.push(comp[1]);
				}
				pos.push(comp[0]);
				// log 'pos-', comp
			} else {
				pos.push(part);
			}
		}
		return [pos, neg];
	};


const rawCompare = function(compare, p) {
	// lowercase and remove spaces and stuff

	compare = compare.toLowerCase().replace(/[^\w]/g, '').replace('accept', '');
	p = p.toLowerCase().replace(/[^\w]/g, '').replace('accept', '');

	// calculate the length of the shortest one
	const minlen = Math.min(compare.length, p.length);

	const diff = levens(compare.slice(0, minlen), p.slice(0, minlen));
	const accuracy = 1 - (diff / minlen);

	if ((minlen >= 4) && (accuracy >= 0.65)) {
		return true;
	}

	return false;
};

const splitWords = function(text) {
    let word;
    const arr = ((() => {
        const result = [];
        for (word of Array.from(text.toLowerCase().split(/[\s\/\-]+/))) {
            result.push(word.trim());
        }
        return result;
    })());
    const words = ((() => {
        const result1 = [];
        for (word of Array.from(arr)) {
            if (!Array.from(stopwords).includes(word) && (word !== '')) {
                result1.push(stem(word));
            }
        }
        return result1;
    })());
    return words;
};

const checkAnswer = function(compare, answer) {
		let r;
		var question = '';
		compare = compare.replace(/\{|\}/g, '');
		answer = answer.replace(/\{|\}/g, '');

		question = removeDiacritics(question).trim();
		answer = removeDiacritics(answer).trim();
		compare = removeDiacritics(compare).trim();

		const questionWords = splitWords(question);

        // (word for word in splitWords(compare) when word not in questionWords)

        const inputText = []
		// const inputText = Array.from(splitWords(compare)).filter((word) => !Array.from(questionWords).includes(word));

		const [pos, neg] = Array.from(parseAnswer(answer.trim()));

		const responses = [];
		for (let p of Array.from(pos)) {
			// checking years because theyre numbers
			if (compare.replace(/[^0-9]/g, '').length === 4) {

				const year = compare.replace(/[^0-9]/g, '');
				const compyr = p.replace(/[^0-9]/g, '');

				if (year === compyr) {
					responses.push(true);
				}
			} else {

				responses.push(rawCompare(compare, p));
			}
		}

		for (r of Array.from(responses)) {
			if (r === true) { return true; }
		}

		for (r of Array.from(responses)) {
			if (r === "prompt") { return "prompt"; }
		}

		return false;
	};

export {checkAnswer}
