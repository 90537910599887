import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import './QuestionDisplay.css'


function QuestionDisplay(props) {
    let textToShow;

    if(props.isShowingCategory) {
        textToShow = <div class="chosen-category-container">
                        <div class="category-text"> {props.question["category"]} </div>
                        <div class="value-text"> {props.question["value"]} </div>
                    </div>
    } else {
        textToShow = <div class="question-text">
                        {props.question["question"]}
                    </div>
    }

    return (
        <div class="question-container">
            {textToShow}
        </div>
    );
}

export default withRouter(QuestionDisplay);
