import React, { Component } from 'react';
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import './ScoreBoard.css'

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function ScoreBoard(props) {
    // In props, we will get all the information: username, user_id, canvas_image.
    // In state, we will have a key-value map of user_id
    return(
        <div className="scoreboard">
            {props.usersInRoom.map(function(user, idx){
               return (
                   <div className="scoreboard-element-container">
                        <div className={props.scores[user.user_id] >= 0 ? "scoreboard-element-score" : "scoreboard-element-negative-score"}>
                            $ {numberWithCommas(props.scores[user.user_id])}
                        </div>
                        <img className="scoreboard-element-canvas-image" src={user.canvas_image}/>
                    </div>
                  )
             })}
        </div>
    );
}

export default withRouter(ScoreBoard);
