import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'

import './GameSummary.css'

function sortScores(dict) {
    var items = Object.keys(dict).map(function(key) {
        return [key, dict[key]];
    });

    // Sort the array based on the second element
    items.sort(function(first, second) {
      return second[1] - first[1];
    });

    return items
}

function getUserNameFromId(id, users) {
    var canvas_image=""
    for(var i=0; i<users.length; i++) {
        if(users[i].user_id == id) {
            canvas_image =  users[i].canvas_image;
            break;
        }
    }
    return canvas_image
}

function GameSummary(props) {
    let sortedScores = sortScores(props.scores)

    return(
        <div className="game-summary-content">
            <div className="winner-container">
                <h2>CHAMPION</h2>
                <div className="winner-info-box">
                    <div className="winner-info-score">
                        $ {sortedScores[0][1]}
                    </div>
                    <img className="winner-info-image" src={getUserNameFromId(sortedScores[0][0], props.usersInRoom)}/>
                </div>
            </div>
            <div className="runners-up-container">
            {sortedScores.map((user, idx) =>{
                if(idx > 0) {
                    return (
                        <div className="runner-up-info-container">
                             <div className="runner-up-info-box">
                            <div className="runner-up-info-score">
                                $ {sortedScores[idx][1]}
                            </div>
                                 <img className="runner-up-info-image" src={getUserNameFromId(sortedScores[idx][0], props.usersInRoom)}/>
                            </div>

                            # {idx + 1}
                        </div>
                       )
                }
             })}
            </div>
         </div>
    );
}

export default withRouter(GameSummary);
