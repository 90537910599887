import React, { Component } from 'react';
import Select from 'react-select'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import './WaitingRoom.css'

const difficultyOptions = [
  { value: 'easy', label: 'Easy' },
  { value: 'medium', label: 'Medium' },
  { value: 'hard', label: 'Hard' }
]


class WaitingRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteLinkText:'Hover over me to see the invite link!',
            selectedDifficulty:''
        };
        this.handleDifficultyChange = this.handleDifficultyChange.bind(this)
        this.startGame = this.startGame.bind(this)
	}

    //set the text
   onMouseOverInviteLink (e) {
       this.setState({inviteLinkText : this.props.frontendRootURL + "?room=" + this.props.roomId})
   }
   //clear the text
   onMouseOutInviteLink (e) {
       this.setState({inviteLinkText : 'Hover over me to see the invite link!'})
   }

   handleDifficultyChange = (selectedOption) => {
       this.setState({ selectedDifficulty: selectedOption.value });
    }

    startGame = () => {
        if(this.state.selectedDifficulty == ''){
            alert("please select a question difficulty level")
        } else {
            this.props.startGame(this.state.selectedDifficulty)
        }
    }

    render() {
        return (
            <div className="waiting-room-container">
                <div className="waiting-room-boxes-container">
                    <div className="waiting-room-settings-container">
                        <h2>Game Settings</h2>
                            <div className={this.props.didCreateRoom ? "waiting-room-settings-box" : "waiting-room-settings-box-off"}>
                                <div className="select-difficulty-container">
                                    Question difficulty
                                    <Select
                                        onChange={this.handleDifficultyChange}
                                        className="select-difficulty"
                                        options={difficultyOptions}
                                        placeholder="Difficulty"/>
                                </div>
                                <button className="start-game-button" onClick={this.startGame}> START GAME </button>
                            </div>
                    </div>
                    <div className="waiting-room-players-container">
                        <h2>Players ({this.props.usersInRoom.length})</h2>
                            <div className="waiting-room-players-box">
                              {this.props.usersInRoom.map(function(d, idx){
                                 return (
                                     <div className="player-info-container">
                                        <div className="player-info-name">
                                            {d.username}
                                        </div>
                                        <img className="player-info-image" src={d.canvas_image}/>
                                    </div>
                                    )
                               })}
                            </div>
                    </div>
                </div>
                <div className="invite-friends-container">
                    <h2>Invite your friends!</h2>
                     <div onMouseEnter={this.onMouseOverInviteLink.bind(this)} onMouseOut={this.onMouseOutInviteLink.bind(this)} className="invite-friends-link-box">
                        {this.state.inviteLinkText}
                    </div>
                 </div>
            </div>
        );
    }
}

export default WaitingRoom;
